<template>
  <QDialog
    v-model="show"
    persistent
  >
    <QCard class="w-full md:w-1/3">
      <QBtn
        icon="close"
        color="white"
        unelevated
        square
        dense
        class="absolute top-0 right-0 z-50 !bg-secondary"
        @click="show = false"
      />
      <QImg
        :src="settingStore.site.welcome_new_user_image"
      />
    </QCard>
  </QDialog>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth.js'
import { whenever } from '@vueuse/core'
import { ref } from 'vue'
import dayjs from 'dayjs'
import { useSettingStore } from '@/stores/setting'

const store = useAuthStore()
const settingStore = useSettingStore()


const show = ref(false)

whenever(() => store.isLoggedIn, () => {
  if (dayjs(store.user.created_at).isToday() && settingStore.site.welcome_new_user){
    show.value = true
  }
})

</script>
