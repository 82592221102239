<template>
  <QDialog
    v-model="authStore.showGuestQuestionModal"
    persistent
  >
    <div class="!max-w-screen w-full md:(w-5xl px-35) pt-6 px-6  !shadow-none">
      <div class="relative rounded dark:bg-dark-2 light:bg-light-2">
        <QBtn
          v-close-popup
          to="/"
          icon="close"
          flat
          round
          dense
          size="18px"
          color="white"
          class="absolute -top-6 -right-6 !bg-secondary"
        />
        <QForm
          class="flex flex-nowrap items-center relative text-dark-5"
          @submit.prevent="submit"
        >
          <div
            v-if="$q.screen.gt.sm"
            class="w-1/3"
          >
            <img
              :src="img"
              class="w-4/7 absolute bottom-0 -left-40"
              alt=""
            >
          </div>
          <div class="w-full p-5 md:(w-2/3 p-10)">
            <div class="flex flex-nowrap items-center gap-2 w-full border-b border-secondary text-2xl font-bold py-5">
              <Logo
                class="w-35"
                :dark="$q.dark.isActive"
              />
              비회원문의
            </div>
            <div class="flex flex-col gap-3">
              <div class="text-base font-bold">
                <QIcon
                  name="person"
                  size="xs"
                  color="secondary"
                />
                아이디
              </div>
              <QInput
                v-model="form.name"
                placeholder="회원ID, 텔레그램ID 입력하세요"
                outlined
                dense
                :bg-color="$q.dark.isActive ? 'dark-1':'light-1'"
              />
            </div>
            <div class="flex flex-col gap-3">
              <div class="text-base font-bold">
                <QIcon
                  name="phone_in_talk"
                  size="xs"
                  color="secondary"
                />
                휴대폰번호
              </div>
              <QInput
                v-model="form.mobile"
                placeholder="휴대폰번호를 입력하세요"
                outlined
                dense
                :bg-color="$q.dark.isActive ? 'dark-1':'light-1'"
              />
            </div>
            <div class="flex flex-col gap-3">
              <div class="text-base font-bold">
                <QIcon
                  name="edit_note"
                  size="xs"
                  color="secondary"
                />
                문의내용
              </div>
              <QInput
                v-model="form.content"
                placeholder="문의 내용을 입력하세요"
                outlined
                dense
                type="textarea"
                :bg-color="$q.dark.isActive ? 'dark-1':'light-1'"
              />
            </div>
            <div class="md:mt-8">
              <QBtn
                color="secondary"
                unelevated
                rounded
                class="h-15 w-full"
                :loading="questionStore.creating"
                type="submit"
              >
                <div class="text-base font-bold">
                  보내기
                </div>
              </QBtn>
            </div>
          </div>
        </QForm>
      </div>
    </div>
  </QDialog>
</template>

<script setup>
import Logo from '@/components/Logo.vue'
import { useAuthStore } from '@/stores/auth.js'
import { useQuestionStore } from '@/stores/question.js'
import { reactive } from 'vue'
import img from '@/assets/modal/guest-question.png'

const authStore = useAuthStore()
const questionStore = useQuestionStore()

const form = reactive({
  name: '',
  mobile: '',
  content: ''
})

async function submit(){
  await questionStore.guest({
    title: `${form.name}|${form.mobile}`,
    content: form.content,
  })
  authStore.showGuestQuestionModal = false
}
</script>
