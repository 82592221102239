<template>
  <QDialog
    v-model="couponStore.showCouponModal"
    persistent
  >
    <div class="!max-w-screen w-full md:(w-5xl px-35) pt-6 px-6 !shadow-none">
      <div class="relative rounded dark:bg-dark-2 light:bg-light-2">
        <QBtn
          v-close-popup
          icon="close"
          flat
          round
          dense
          size="18px"
          color="white"
          class="absolute -top-6 -right-6 !bg-secondary"
        />
        <div class="flex flex-nowrap items-center relative text-dark-5">
          <div
            v-if="$q.screen.gt.sm"
            class="w-1/3"
          >
            <img
              :src="img"
              class="w-1/2 absolute bottom-5 -left-25"
              alt=""
            >
          </div>
          <div class="w-full p-5 md:(w-2/3 p-10) flex flex-col gap-5">
            <div class="flex flex-nowrap items-center gap-2 w-full border-b border-secondary text-2xl font-bold py-5">
              <Logo
                class="w-35"
                :dark="$q.dark.isActive"
              />
            </div>
            <div class="flex flex-col justify-center items-center gap-2 py-5">
              <div class="text-5xl font-bold">
                <CurrencyField :value="authStore.coupon.amount" />
              </div>
              <div class="">
                POINT
              </div>
              <div class="text-2xl text-primary font-bold mt-8">
                {{ authStore.coupon.title }}
              </div>
            </div>
            <div>
              <QBtn
                v-close-popup
                color="secondary"
                unelevated
                rounded
                class="h-15 w-full"
                @click="exchange"
              >
                <div class="text-base font-bold">
                  쿠폰받기
                </div>
              </QBtn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </QDialog>
</template>

<script setup>
import Logo from '@/components/Logo.vue'
import CurrencyField from '@/components/Tables/Fields/CurrencyField.vue'
import { useCouponStore } from '@/stores/coupon'
import { useAuthStore } from '@/stores/auth'
import img from '@/assets/modal/coupon.png'

const couponStore = useCouponStore()
const authStore = useAuthStore()

async function exchange(){
  await couponStore.exchange(authStore.coupon.id)
  await authStore.fetch()
}
</script>
